export default [
  {
    header: 'Support',
    action: 'read',
    resource: 'User',
  },
  {
    icon: 'ClipboardIcon',
    title: 'Factures',
    route: 'facture',
    action: 'read',
    resource: 'User',
  },
  {
    icon: 'CreditCardIcon',
    title: 'Moyen de paiement',
    route: 'paiement',
    action: 'read',
    resource: 'User',
  },
  {
    icon: 'MailIcon',
    title: 'Messages',
    route: 'message',
    action: 'read',
    resource: 'User',
    messagerie: '1',
  },
  {
    icon: 'PhoneIcon',
    title: 'Rendez-vous',
    route: 'meet',
    action: 'read',
    resource: 'User',
  },
]
