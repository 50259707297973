export default [
  {
    header: 'Admin',
    action: 'read',
    resource: 'Admin',
  },
  // {
  //   icon: 'SunIcon',
  //   title: 'Gestion demandes',
  //   route: 'gestion',
  //   action: 'read',
  //   resource: 'Admin',
  //   gestion_demandes: '1',
  // },
  {
    icon: 'CheckCircleIcon',
    title: 'Tâches',
    route: 'tache',
    action: 'read',
    resource: 'Admin',
  },
  {
    icon: 'MailIcon',
    title: 'Messagerie clients',
    route: 'messageadmin',
    action: 'read',
    resource: 'Admin',
    messagerie_admin: '1',
  },
  {
    icon: 'UsersIcon',
    title: 'Clients',
    route: 'admin',
    action: 'read',
    resource: 'Admin',
  },
  {
    icon: 'ActivityIcon',
    title: 'Logs',
    route: 'logs',
    action: 'read',
    resource: 'Admin',
  },
  {
    icon: 'BarChartIcon',
    title: 'Indicateurs',
    route: 'indicateurs',
    action: 'read',
    resource: 'Admin',
  },
]
