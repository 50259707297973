/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import design from './design'
import audience from './audience'
import parrainage from './parrainage'
import drive from './drive'
import support from './support'
import admin from './admin'

// Array of sections
export default [...dashboard, ...drive, ...parrainage, ...support, ...admin]
